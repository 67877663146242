import React, { Fragment, useEffect, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { useDispatch, useSelector } from 'react-redux';
import { setOpenModal } from '../../stores/modal';
import { useSpring, animated } from "react-spring";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import {CircleStackIcon} from "@heroicons/react/20/solid";

export default function CreatePublisher() {
    const { openModal } = useSelector(state => state.modal);
    const dispatch = useDispatch();
    const [isFocused, setIsFocused] = useState(false);
    const inputRef = useRef(null);
    const { credential, partnerIds, isAdmin } = useSelector((state) => state.user);
    const [createPublisher, setCreatePublisher] = useState('');
    const navigate = useNavigate();
    const [createPartner, setCreatePartner] = useState("");

    const closeModal = () => {
        dispatch(setOpenModal(false));
    }

    const labelProps = useSpring({
        top: isFocused ? "-16px" : "0",
        fontSize: isFocused ? "12px" : "16px",
    });

    const handleInputClick = () => {
        setIsFocused(true);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (inputRef.current && !inputRef.current.contains(event.target)) {
                setIsFocused(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleCreatePublisher = async () => {
        const headers = {
            Authorization: `Bearer ${credential}`,
            'Content-Type': 'application/json',
            partnerId: createPartner,
        };
        try {
            const response = await axios.post('https://34.159.97.218/publisher', {
                name: createPublisher
            }, {
                headers: headers
            });
            const createdPublisher = response.data;
            setCreatePublisher(createdPublisher);
            dispatch(setOpenModal(false));
            window.location.href = '/catalog/publishers';
        } catch (error) {
            console.error('API isteği sırasında bir hata oluştu:', error);
        }

    };

    return (
        <Transition.Root show={openModal} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={closeModal}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-primary bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                                <div>
                                    <div className='text-lg font-normal px-4 pt-5'>
                                        <h1>Create New Publisher</h1>
                                    </div>
                                    <div className="mt-3 sm:mt-5 flex items-center gap-3 px-4">
                                        <div className="relative w-full">
                                            <animated.label
                                                className={isFocused ? "absolute left-0 inline-block bg-white px-0 font-normal text-gray-400" : "absolute top-0 left-0 inline-block bg-white px-0 font-normal text-gray-400"}
                                                style={labelProps}
                                            >
                                                Publisher name*
                                            </animated.label>
                                            <input
                                                type="text"
                                                name="name"
                                                id="name"
                                                value={createPublisher}
                                                onChange={(e) => setCreatePublisher(e.target.value)}
                                                onClick={handleInputClick}
                                                ref={inputRef}
                                                className="block w-full border-b border-gray-300 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6 hover:border-primary hover:border-b-2"
                                            />
                                        </div>
                                    </div>

                                    {isAdmin && (
                                        <div className="p-4">
                                            <div className="relative w-full">
                                                <animated.label className="absolute top-0 left-0 inline-block bg-white px-1 font-normal text-gray-400">
                                                    Select Partner
                                                </animated.label>
                                                <div className="relative">
                                                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                                        <CircleStackIcon
                                                            className="h-4 w-4 text-gray-300"
                                                            aria-hidden="true"
                                                        />
                                                    </div>

                                                    <select
                                                        className="block w-full rounded-md bg-white py-2 pl-7 pr-10 border border-gray-300 text-gray-400 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:outline-none sm:text-sm"
                                                        onChange={(e) => setCreatePartner(e.target.value)}
                                                    >
                                                        <option value="">Select Partner</option>
                                                        {partnerIds?.map((partner) => (
                                                            <option key={partner.id} value={partner.id}>
                                                                {partner.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className=" sm:flex items-center justify-between sm:gap-3 bg-[#eee] p-4">
                                    <div>
                                    </div>
                                    <div className='flex items-center gap-4'>
                                        <button
                                            type="button"
                                            className="mt-3 inline-flex justify-center rounded-primary px-3 py-2 text-sm font-medium text-gray-900 uppercase hover:bg-gray-200 sm:col-start-1 sm:mt-0"
                                            onClick={closeModal}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            type="button"
                                            className="inline-flex justify-center rounded-md bg-primary px-5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-primary/80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 uppercase sm:col-start-2"
                                            onClick={handleCreatePublisher}
                                        >
                                            Create
                                        </button>
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
