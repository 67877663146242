import axios from "axios";
import { cleanHeaders } from "../clean-headers";

const getArtists = async (params) => {
  const headers = cleanHeaders(params);

  try {
    return await axios.get(
        "https://34.159.97.218/artist",
        {headers}
    ).then((res) => res.data);
  } catch (error) {
    console.log(error);
  }
};

export { getArtists };
