import React, {Fragment, useEffect, useRef, useState} from 'react'
import {Dialog, Transition} from '@headlessui/react'
import {useDispatch, useSelector} from 'react-redux';
import {setOpenModal} from '../../stores/modal';
import {ArrowDownIcon, CircleStackIcon, PlusIcon, TrashIcon} from '@heroicons/react/20/solid';
import axios from 'axios';
import {toast, ToastContainer} from 'react-toastify';
import {useCookies} from "react-cookie";
import Loading from "../Loading";
import {animated} from "react-spring";

export default function CreateAssets() {
    const { credential, partnerIds, isAdmin } = useSelector((state) => state.user);
    const {openModal} = useSelector(state => state.modal);
    const dispatch = useDispatch();
    const [isFocused, setIsFocused] = useState(false);
    const inputRef = useRef(null);
    const [newAssets, setNewAssets] = useState([]);
    const [createAsset, setCreateAsset] = useState();
    const [assetName, setAssetName] = useState('');
    const [trackVersion, setTrackVersion] = useState('');
    const [cookie, setCookie] = useCookies(["selectedPartnerId"]);

    //Upload Audio File
    const [createAudio, setCreateAudio] = useState();
    const [audioFile, setAudioFile] = useState();
    const [loading, setLoading] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);

    const [createPartner, setCreatePartner] = useState("");

    const headerSet = {
        Authorization: `Bearer ${credential}`, // PartnerId: getPartnerIds(partnerIds),
    };

    const closeModal = () => {
        dispatch(setOpenModal(false));
    }

    const handleFileUpload = async (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (inputRef.current && !inputRef.current.contains(event.target)) {
                setIsFocused(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleCreateAsset = async () => {
        setLoading(true);

        const headers = {
            Authorization: `Bearer ${credential}`,
            partnerId: createPartner,
        };

        const body = new FormData();
        body.append('audioFile', selectedFile);
        body.append('title', assetName);
        body.append('trackVersion', trackVersion);

        try {
            const response = await axios.post('https://34.159.97.218/asset/createWithAudioFile', body, {
                headers: headers
            });
            const createdAsset = response.data;

            setCreateAsset(createdAsset);
            dispatch(setOpenModal(false));
            setLoading(false);
            window.location.reload();
            window.location.href = `/catalog/assets/${createdAsset.id}`;
        } catch (error) {
            toast.error('API isteği sırasında bir hata oluştu:', error);
        }

    };

    if (loading) {
        return <Loading/>;
    }

    return (<>
            <ToastContainer autoClose={3000}/>
            <Transition.Root show={openModal} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={closeModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div
                            className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel
                                    className="relative transform overflow-hidden rounded-primary bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl">
                                    <div>
                                        <div className='flex items-center justify-between cursor-pointer'>
                                            <div className='text-lg font-normal px-4 pt-5'>
                                                <h1>Create New Assets</h1>
                                            </div>
                                        </div>
                                        <div className="mt-3 sm:mt-5 flex items-center gap-3 px-4">
                                            <table className="w-full text-left">
                                                <thead className="bg-white">
                                                <tr>
                                                    <th scope="col"
                                                        className="relative isolate py-3.5 pr-3 text-left text-[11px] font-normal text-[#212121] uppercase w-36">
                                                        Title
                                                        <div
                                                            className="absolute inset-y-0 right-full -z-10 w-screen border-b border-b-[#E2E2E2]"/>
                                                        <div
                                                            className="absolute inset-y-0 left-0 -z-10 w-screen border-b border-b-[#E2E2E2]"/>
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="hidden px-3 py-3.5 text-left text-[11px] font-normal text-[#212121] uppercase sm:table-cell sm:w-36"
                                                    >
                                                        Track Version
                                                    </th>
                                                    <th scope="col"
                                                        className="px-3 py-3.5 text-left text-[11px] font-normal text-[#212121] uppercase w-48">
                                                        File
                                                    </th>
                                                </tr>
                                                </thead>
                                                <thead className="bg-white">
                                                <tr>
                                                    <th
                                                        scope="col"
                                                        className="hidden text-left pr-6 text-[11px] font-normal text-[#212121] uppercase sm:table-cell sm:w-36"
                                                    >
                                                        <input type='text' placeholder='Title' value={assetName}
                                                               onChange={(e) => setAssetName(e.target.value)}
                                                               className='w-full border-b-2 text-[16px] outline-none focus:border-b-primary hover:border-b-primary'/>
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="hidden text-left pr-6 text-[11px] font-normal text-[#212121] uppercase sm:table-cell sm:w-36"
                                                    >
                                                        <input type='text' placeholder='Enter Version'
                                                               value={trackVersion}
                                                               onChange={(e) => setTrackVersion(e.target.value)}
                                                               className='w-full border-b-2 text-[16px] outline-none focus:border-b-primary hover:border-b-primary'/>
                                                    </th>
                                                    <th scope="col"
                                                        className="px-3 py-3.5 text-left text-[11px] font-normal text-[#212121] uppercase w-36">
                                                        <div
                                                            className='flex items-center gap-1 hover:bg-gray-200 w-fit rounded-primary p-2'>
                                                            <ArrowDownIcon className='w-6 h-6 text-primary'/>
                                                            <label htmlFor="upload-button"
                                                                   className='cursor-pointer truncate w-48 flex-1'>
                                                                {selectedFile ? selectedFile.name : "Add File"}
                                                            </label>
                                                            <input
                                                                className="hidden"
                                                                type="file"
                                                                id="upload-button"
                                                                onChange={handleFileUpload}/>
                                                        </div>

                                                    </th>
                                                </tr>
                                                </thead>
                                            </table>
                                        </div>
                                        {isAdmin && (
                                        <div className="p-4">
                                            <div className="relative w-full">
                                                <animated.label className="absolute top-0 left-0 inline-block bg-white px-1 font-normal text-gray-400">
                                                    Select Partner
                                                </animated.label>
                                                <div className="relative">
                                                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                                        <CircleStackIcon
                                                            className="h-4 w-4 text-gray-300"
                                                            aria-hidden="true"
                                                        />
                                                    </div>

                                                    <select
                                                        className="block w-full rounded-md bg-white py-2 pl-7 pr-10 border border-gray-300 text-gray-400 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:outline-none sm:text-sm"
                                                        onChange={(e) => setCreatePartner(e.target.value)}
                                                    >
                                                        <option value="">Select Partner</option>
                                                        {partnerIds?.map((partner) => (
                                                            <option key={partner.id} value={partner.id}>
                                                                {partner.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        )}
                                    </div>
                                    <div className=" sm:flex items-center justify-between sm:gap-3 bg-[#eee] p-4">
                                        <div>
                                        </div>
                                        <div className='flex items-center gap-4'>
                                            <button
                                                type="button"
                                                className="mt-3 inline-flex justify-center rounded-primary px-3 py-2 text-sm font-medium text-gray-900 uppercase hover:bg-gray-200 sm:col-start-1 sm:mt-0"
                                                onClick={closeModal}
                                            >
                                                Cancel
                                            </button>
                                            <button
                                                type="button"
                                                className="inline-flex justify-center rounded-md bg-primary px-5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-primary/80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 uppercase sm:col-start-2"
                                                onClick={handleCreateAsset}
                                            >
                                                Create
                                            </button>
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>)
}
